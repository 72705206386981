'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import axios from 'axios'
Vue.prototype.axios = axios

export const store = new Vuex.Store({
  namespaced: true,
  state: {
    screen: 0,
    opaque: false,
    pictureRange: 'medium',
    preloads: [],
    loading: true,
    homepages: [],
    architectures: [],
    produits: [],
    about: [],
    lastPage: 'Opening',
    year: ''
  },
  getters: {
    getArchitectureBySlug: (state) => (slug) => {
      return state.architectures.find(architectures => architectures.slug === slug)
    },
    getProduitsBySlug: (state) => (slug) => {
      return state.produits.find(produits => produits.slug === slug)
    }
  },
  mutations: {
    setRequest(state, payload) {
      state[payload.page] = payload.data
    },
    setPreloads(state, img) {
      state.preloads.push(img)
    },
    loadingState(state, loading) {
      state.loading = loading
    },
    screenSize(state, size) {
      state.screen = size
    },
    screenOpaque(state, bool) {
      state.opaque = bool
    },
    setYear(state, year) {
      state.year = year
    },
    setDevice(state, name) {
      state.device = name
    },
    setPictureRange(state, screen) {
      state.pictureRange = screen
    },
    setLastPage(state, name) {
      state.lastPage = name
    }
  },
  actions: {
    getScreen ({ commit }) {
      commit('screenSize', screen.width)
    },
    getDensity ({ commit }) {
      if (window.devicePixelRatio*screen.width > 2000) {
        commit('setPictureRange', 'large')
      }
    },
    getYear ({ commit }) {
      const today = new Date()
      commit('setYear', today.getFullYear())
    },
    ajaxRequests ({ commit }) {
      axios.all([
        // Homepages
        axios.get('https://akirastudio.co/wp-json/wp/v2/posts/?categories=4&fields=categories,slug,title,acf'),
        // Produits
        axios.get('https://akirastudio.co/wp-json/wp/v2/posts/?categories=3&fields=slug,title,acf'),
        // Architectures
        axios.get('https://akirastudio.co/wp-json/wp/v2/posts/?categories=2&fields=slug,title,acf'),
        // About
        axios.get('https://akirastudio.co/wp-json/wp/v2/pages/?id=2&fields=title,acf')
      ])
      .then(axios.spread((homepageRes, produitsRes, architectureRes, aboutRes) => {

        commit('setRequest', {page: 'homepages', data: homepageRes.data})
        commit('setRequest', {page: 'produits', data: produitsRes.data})
        commit('setRequest', {page: 'architectures', data: architectureRes.data})
        commit('setRequest', {page: 'about', data: aboutRes.data[0]})
        commit('loadingState', false)
        
        const lp = produitsRes.data.length
        const la = architectureRes.data.length

        commit('setPreloads', aboutRes.data[0].acf.imagehome.sizes[this.state.pictureRange])
        commit('setPreloads', aboutRes.data[0].acf.imageabout.sizes[this.state.pictureRange])

        for (let i = lp; i--;) {
          commit('setPreloads', produitsRes.data[i].acf.feature1.sizes[this.state.pictureRange])
          commit('setPreloads', produitsRes.data[i].acf.feature2.sizes[this.state.pictureRange])
          commit('setPreloads', produitsRes.data[i].acf.feature3.sizes[this.state.pictureRange])
        }

        for (let l = la; l--;) {
          commit('setPreloads', architectureRes.data[l].acf.feature1.sizes[this.state.pictureRange])
          commit('setPreloads', architectureRes.data[l].acf.feature2.sizes[this.state.pictureRange])
          commit('setPreloads', architectureRes.data[l].acf.feature3.sizes[this.state.pictureRange])
        }

      }))
      .catch((homepageRes, produitsErr, architectureErr, aboutErr) => { 
        //console.log(homepageRes, produitsErr, architectureErr, aboutErr)
      })
    }
  }
})