<template>
  <div>
    <template v-if="!loading">
      <router-view></router-view>
      <router-view name="modal"></router-view>
      <component :is="message"></component>
      <component :is="nav"></component>
      <apppreloader></apppreloader>   
    </template>
  </div>
</template>

<script>
import apppreloader from '@/components/desktop/preloader'
import { mapState } from 'vuex'
import debounce from 'lodash.debounce'

export default {
  name: 'App',
  components: {
    apppreloader,
    appnavigationmobile : () => import('@/components/mobile/navigation'),
    appnavigation : () => import('@/components/desktop/navigation'),
    resizemessage : () => import('@/components/desktop/resizemessage')
  },
  computed: {
    ...mapState([
      'loading',
      'screen'
    ]),
    nav() {
      const の = this
      if (の.screen <= 740) {
        return 'appnavigationmobile'
      } else {
        return 'appnavigation'
      }
    },
    message() {
      const の = this
      if (の.screen > 740) {
        return 'resizemessage'
      }
    }
  },
  mounted() {
    const の = this
    の.setDocumentHeight()
    window.addEventListener('resize', debounce(の.setDocumentHeight, 400), false)
    の.setUserAgent()
  },
  methods: {
    setDocumentHeight() {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)    
    },
    setUserAgent() {
      document.documentElement.setAttribute('data-useragent', navigator.userAgent)
    }
  }
}
</script>

<style lang="stylus">
 @require "./styles/resets"
 @require "./styles/fontface"
 @require "./styles/variables"
 
html
  box-sizing border-box

*, 
*:before, 
*:after
  box-sizing inherit

html,
body
  height 100vh
  height calc(var(--vh, 1vh) * 100)
  width 100vw
  overflow hidden

body
  font-family 'Fl', sans-serif
  font-size 14px
  line-height 1.5rem
  color $grey

a
  color $grey
  text-decoration none
  transition color .4s ease

.Gr
  color $greyL
.Re
  color $red
.Wh
  color white
.Ud
  text-decoration underline
.Fl
  font-family 'Fl'
.Fm
  font-family 'Fm'

svg
  transition fill .4s ease
      
a::-moz-focus-inner,
button::-moz-focus-inner
  border 0

h1,
h2,
h3
  font-family 'Fm'

h2
  font-size 2.5rem
  line-height 1

@media (min-width: 1181px)
  body
    font-size 15px

html[data-useragent*='MSIE'],
html[data-useragent*='Edge'],
html[data-useragent*='IEMobile'],
html[data-useragent*='Opera Mini'],
html[data-useragent*='BlackBerry'],
/*@supports not (-webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)) or (clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%))*/
  .F01,
  .F02,
  .F03
    > img
      opacity 0
      &.Ac
        opacity 1
  .Im
    opacity 0
    &.Ac
      opacity 1
</style>