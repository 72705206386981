'use strict'

import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

const d = ((screen.width > 740) ? 'desktop' : 'mobile')

function loadView(view) {
  return () => import(/* webpackChunkName: "akirastudio.[request]" */ `@/components/${d}/${view}.vue`).then(m => m.default)
}

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'homepages',
      component: loadView('homepage')
    },
    {
      path: '/produits',
      name: 'produits',
      component: loadView('gridproduits'),
      meta: { 
        category: 'produits'
      }
    },
    {
      path: '/produits/grid',
      name: 'gridprojetproduits',
      component: loadView('gridproduits'),
      components: {
        default: loadView('gridproduits'),
        modal: loadView('modal')
      },
      meta: {
        category: 'produits'
      }
    },
    {
      path: '/produits/:id',
      name: 'projetproduits',
      components: {
        default: loadView('gridproduits'),
        modal: loadView('projet')
      },
      meta: { 
        category: 'produits'
      }
    },
    {
      path: '/architecture',
      name: 'architectures',
      component: loadView('gridarchitectures'),
      meta: { 
        category: 'architectures'
      }
    },
    {
      path: '/architecture/grid',
      name: 'gridprojetarchitectures',
      components: {
        default: loadView('gridarchitectures'),
        modal: loadView('modal')
      },
      meta: {
        category: 'architectures'
      }
    },
    {
      path: '/architecture/:id',
      name: 'projetarchitectures',
      components: {
        default: loadView('gridarchitectures'),
        modal: loadView('projet')
      },
      meta: { 
        category: 'architectures'
      }
    },
    {
      path: '/a-propos',
      name: 'apropos',
      component: loadView('about')
    },
    {
      path: '*', 
      redirect: { name: 'homepages' }
    }
  ]
})
