'use strict'

import Vue from 'vue'

import { store } from './store'

import { router } from './router'

import debounce from 'lodash.debounce'
Vue.prototype.Debounce = debounce

import { TweenLite } from "gsap/TweenMaxBase"

import {
  TimelineLite,
  Power1,
  Power3
} from "gsap/all"
// const Th = ThrowPropsPlugin
// import { Draggable } from 'gsap/Draggable'
import * as Draggable from "gsap/Draggable"
import { ThrowPropsPlugin } from 'gsap/ThrowPropsPlugin'
//if tree shaking dumps plugins, just reference them somewhere in your code like:
const activated = [
  TweenLite,
  TimelineLite, 
  Power1, 
  Power3, 
  Draggable, 
  ThrowPropsPlugin
]

Vue.prototype.TweenLite = TweenLite
Vue.prototype.TimelineLite = TimelineLite
Vue.prototype.Power1 = Power1
Vue.prototype.Power3 = Power3
Vue.prototype.Draggable = Draggable
Vue.prototype.ThrowPropsPlugin = ThrowPropsPlugin

import { mapActions, mapState } from 'vuex'

import App from './App.vue'

new Vue({
  router,
  store,
  data() {
    return {
      desktopComponents: [
        'desktopComponent01',
        'desktopComponent02',
        'desktopComponent03',
        'desktopComponent04',
        'desktopComponent05',
        'desktopComponent06',
        'desktopComponent07',
        'desktopComponent08',
        'desktopComponent09',
        'desktopComponent10',
        'desktopComponent11',
        'desktopComponent12'
      ],
      mobileComponents: [
        'mobileComponent01',
        'mobileComponent02',
        'mobileComponent03',
        'mobileComponent04',
        'mobileComponent05',
        'mobileComponent06',
        'mobileComponent07',
        'mobileComponent08',
        'mobileComponent09',
        'mobileComponent10',
        'mobileComponent11',
        'mobileComponent12'
      ]
    }
  },
  computed: {
    ...mapState([
      'screen'
    ])
  },
  methods: {
    ...mapActions([
      'getScreen', 
      'getDensity',
      'ajaxRequests',
      'getYear',
      'screenOpaque',
      'setLastPage'
    ]),
    addPreloads(filePath){
      const head = document.getElementsByTagName('head')[0]
      let link = document.createElement('link')
      link.href = filePath
      link.rel = 'prefetch'
      head.append(link)
    }
  },
  mounted() {
    const の = this
    の.ajaxRequests()
    の.getScreen()
    の.getDensity()
    の.getYear()
    //Inject component prefecthing in the head
/*    if (の.screen <= 740) {
      の.mobileComponents.forEach(function(element, index) {
        の.addPreloads(の.mobileComponents[index])
      })
    } else {
      の.desktopComponents.forEach(function(element, index) {
        の.addPreloads(の.desktopComponents[index])
      })
    }*/

  },
  el: 'app',
  render: h => h(App)
})

ga('set', 'page', router.currentRoute.path)
ga('send', 'pageview')

router.afterEach(( to ) => {
  ga('set', 'page', to.path)
  ga('send', 'pageview')
})
